import {
  ActivityListType,
  IconsVariants,
} from 'components/Pages/Activity/Content/ProgramsActivity/types';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Activity } from 'reducers/activities/types';
import { StoreType } from 'reducers/types';

const useActivities = () => {
  const activities = useSelector((state: StoreType) => state.activities.all);
  const activitiesByDate: ActivityListType[] = useMemo(
    () =>
      activities
        ? activities.reduce(
            (acc: ActivityListType[], item: Activity, index: number, array: Activity[]) => {
              const extendedItem = {
                ...item,
                icons: item.type === 'future' ? ['calendar'] : ['trophy'],
              };

              if (
                index !== 0 &&
                moment(item.completedAt).isSame(array[index - 1].completedAt, 'day')
              ) {
                acc[acc.length - 1].activities.push(extendedItem);
              } else {
                const iconsList: IconsVariants[] =
                  item.type === 'future' ? ['calendar'] : ['trophy'];

                // Check if the date is valid before formatting
                const completedAtMoment = moment(item.completedAt);
                const formattedDate = completedAtMoment.isValid()
                  ? completedAtMoment.format('DD MMM YY')
                  : 'Invalid date';

                acc.push({
                  date: formattedDate,
                  activities: [extendedItem],
                  icons: iconsList,
                });
              }

              return acc;
            },
            [],
          )
        : [],
    [activities],
  );

  const futureActivities = useMemo(
    () =>
      activitiesByDate.filter((item: ActivityListType) =>
        item.activities.some(activity => activity.type === 'future'),
      ),
    [activitiesByDate],
  );

  return {
    activities: activitiesByDate,
    futureActivities,
  };
};

export default useActivities;
